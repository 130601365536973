import React, { Component } from 'react';

import '../css/Email.css'

class Email extends Component {
    constructor(props) {
        super(props);

        ////////////////////////////////////////////////////////////////
        // local methods
        ////////////////////////////////////////////////////////////////
        this.setEmailStatus = this.setEmailStatus.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        
        ////////////////////////////////////////////////////////////////
        // local component keys
        ////////////////////////////////////////////////////////////////
    }

    ////////////////////////////////////////////////////////////////
    // set email status.  check if email is valid and set flag accordingly
    ////////////////////////////////////////////////////////////////
    setEmailStatus(event) {
        this.props.global.debugMsg('Email: setEmailStatus()');

        // assign value to global object parameter regardless if it a valid email or not
        this.props.global.userEmail = event.target.value;
        if (!this.validateEmail(event.target.value)) {
            this.props.global.emailOk = false;
        }
        else {
            this.props.global.emailOk = true;
        }

        this.props.global.setSectionStates();
    }

    ////////////////////////////////////////////////////////////////
    // validate email format
    ////////////////////////////////////////////////////////////////
    validateEmail(email) {
        var emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/;
        return emailRe.test(String(email).toLowerCase());
    }

    render() {
        this.props.global.debugMsg('Email.js : render()');
        return (
            <div className="container container-margin">
            
                <div className="row">
                    <div className="col-xs-12">
                        <p><span><strong>STEP 3:</strong></span> Enter Email where results are sent</p>
                    </div>
                </div>
            
                <div className="row row-margin">
                    <div className="col-xs-4">
                        <div className="col-xs-5 vcenter">
                            <span>Email</span>
                        </div>
                        
                        <div className="col-xs-7 vcenter">
                            <div className="bootstrap-filestyle input-group">
                                <input type="text" className="form-control " id="email" ref="emailInput" placeholder="" onChange={this.setEmailStatus} disabled={this.props.global.emailInputFormState}></input>
                            </div>
                        </div>
                    </div>        
            
                    <div className="col-xs-4">
                    </div>

                    <div className="col-xs-4">
                    </div>

                </div>
            </div>
        );
    }
}

export default Email;