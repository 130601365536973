import React , { Component } from 'react';
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';
import { Router , Route } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';

import { Provider , connect } from 'react-redux';

import queryString from 'query-string';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'

// import injectTapEventPlugin from 'react-tap-event-plugin';

import Store from './store';

import muiTheme from './Stanford/StanfordMUITheme';
import { loadUserData } from './actions/AuthActions';
import Unauthorized from './components/Unauthorized'; 

import { appAddress , basepath } from './constants'

import App from './components/App';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * 
 * 
 * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

class SSOWrappedAppComponent extends Component {

    constructor( props ) {
        super( props );
        this.state = { loaded  : false , params : undefined };
    }

    componentDidMount() {
        var query = queryString.parse( this.props.location.search );
        this.setState( { loaded : true , params : query } );
        if( typeof query.login !== "undefined" ) {
            this.props.dispatch( loadUserData( query.login ) );
        } else if( typeof query.attributes !== "undefined" ) {

        } else {
            
        }
    }

    render() {
        if( this.props.authorized ) { 
            if( this.state.loaded ) {
                return ( 
                    <MuiThemeProvider muiTheme={muiTheme}>
                        <App params={ this.state.params } />
                    </MuiThemeProvider> 
                );
            } else { return ( <div></div> ); }
        } else { return ( <Unauthorized /> ); }
    }
    
}

const mapStateToProps = ( state , props ) => { 
	return { authorized : state.AuthReducer.authorized };
}

const SSOWrappedApp = connect( mapStateToProps )( SSOWrappedAppComponent );

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * 
 * 
 * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const customHistory = createBrowserHistory();

console.log( "App's address: " + appAddress )
console.log( "App's \"basepath\": " + basepath )

class WrongAddress extends Component {
    render() {
        return (
            <div style={{ marginTop : 20 , fontSize : "14pt" }}>
            { "Wrong URL; maybe try " + appAddress + "/build/auth?login=..." }
            </div>
        );
    }
}

class StanfordSSORouter extends Component {
    render() {
        return (
            <Router history={ customHistory }>
                <div>
					{/* <Route exact path="/" component={ WrongAddress }/> */}
                    <Route exact path={ basepath } component={ Unauthorized }/>
                    <Route exact path={ basepath + ":auth" } component={ SSOWrappedApp }/>
                </div>
            </Router>
        );
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * 
 * 
 * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// injectTapEventPlugin();

const S = Store();

ReactDOM.render( 
	<Provider store={ S }><App /></Provider>, 
		document.getElementById('root')
);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * 
 * 
 * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
