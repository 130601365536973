
import { defaultAuthObject } from './defaults';

export default( state=defaultAuthObject , action ) => {

  switch ( action.type ) {

    case 'DEFINE_SESSION_TOKEN' : return { ...state , token : action.data };

    case 'LOADING_USER_DATA' : return { ...state , loading : true }

    case 'LOADED_USER_DATA'  : 
        console.log( action.data )
    	if( ! action.data ) { return { ...state , loading : false }; }
    	var newState = Object.assign( {} , state );
    	newState.loading = false;
    	if( action.data.expired ) { newState.expired = true; }
    	else {
    		// validate other user data? 
    		newState.authorized = true;
    		newState.user = action.data;
    	}
    	return newState;

    default : return state;

  }

}