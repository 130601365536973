import React, { Component } from 'react';

export default class Unauthorized extends Component {
  render() {
      return (
      <div style={{ marginTop : 20 }}>
        <h1>Unauthorized</h1>
        <p>
        	<div>
        		This site is protected, and requires valid credentials obtained from Stanford SSO. 
            </div>
	  </p>
      </div>
    );
  }
}
