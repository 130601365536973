
// thunk allows for async actions, like responses to loading or API calls
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import Reducer from './reducers/Reducer';

export default( initialState ) => {
    return createStore( Reducer , applyMiddleware(thunkMiddleware) , initialState );
}
