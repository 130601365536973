import React, { Component } from 'react';

import PopPop from 'react-poppop';

import '../css/MessageBox.css'

class MessageBox extends Component {
    constructor(props) {
        super(props);

        ////////////////////////////////////////////////////////////////
        // local methods
        ////////////////////////////////////////////////////////////////
        
        ////////////////////////////////////////////////////////////////
        // local component keys
        ////////////////////////////////////////////////////////////////
    }

    render() {
        this.props.global.debugMsg('MessageBox.js : render()');
        return (
            <div className="container container-margin">

                {/*////////////////////////////////////////////////////////////////*/}
                {/* PopUp Message Box */}
                {/*////////////////////////////////////////////////////////////////*/}
                <div>
                    <PopPop position={this.props.global.msgBoxPos}
                        open={this.props.global.msgBoxState}
                        closeBtn={true}
                        closeOnEsc={true}
                        onClose={() => this.props.global.msgBoxBtnClose(this.props.global.msgBoxCallBack)}
                        closeOnOverlay={true}>
                        <div>
                            <h3 className="text-center">{this.props.global.msgBoxTitle}</h3>
                            <p className="text-center">{this.props.global.msgBoxContent}</p>
                        </div>
                    </PopPop>
                </div>


            </div>
        );
    }
}

export default MessageBox;