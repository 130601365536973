import React, { Component } from 'react';

import '../css/HelpIAM.css'

// https://ctxhou.github.io/react-poppop/
// https://reactjsexample.com/a-mobile-support-and-multi-directional-modal-for-reactjs/
// npm install react-poppop --save
import PopPop from 'react-poppop';

class HelpIAM extends Component {
    render() {
        this.props.global.debugMsg('helpIAM.js : render()');
        return (
            <div>
                <PopPop position={this.props.global.helpAccessMsgBoxPos}
                        open={this.props.global.helpAccess}
                        closeBtn={true}
                        closeOnEsc={true}
                        onClose={() => this.props.global.toggleHelp('helpAccess',false)}
                        closeOnOverlay={true}
                        contentStyle={this.props.global.msgBoxContentStyleDefault}>
                        <div>
                            <div>
                                <div className="mediumpad">
                                    <h3>IAM Keys Instructions</h3>
                                    <br />
                                    In order to use this app, you first need to have access to an AWS account that is linked to the Amazon MTurk account that you are using to run your studies. In addition, you then need to create an IAM (Identity &amp; Access Management) user in your AWS account with full MTurk permissions and generate keys (one set consists of an Access key and a Secret key) for that user. Finally, you can use those keys in this app.<br />
                                    <br />
                                    For in-depth, step-by-step details on the above process, please have a look at the AWS documentation <a href={this.props.global.helpAccessLink} target="_blank" rel="noopener noreferrer">here</a>.<br/>
                                    <br />
                                    After inputting your keys, click “Check Keys” and we will check that your keys are valid and display your account balance. If the keys are valid, you will be allowed to continue to Step 2.
                                    <br />
                                    <br />
                                    <h4>Please note that you are responsible for keeping these keys safe and hiding them in a place where they cannot be compromised. This app encrypts your keys when they get passed in and we do not save them anywhere on our system.</h4>
                                </div>
                            </div>    
                        </div>
                </PopPop>
            </div>
        );
    }
}

export default HelpIAM;