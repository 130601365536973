
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { fade } from 'material-ui/utils/colorManipulator';

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: "#5f574f",
    primary2Color: "#9d9573",
    primary3Color: "#d2c295",
    accent2Color: "#F9F6EF" ,
    textColor: "#2e2d29" ,
    alternateTextColor: "#F9F6EF" ,
    disabledColor: fade("#c7d1c5", 0.3), 
  },
  fontFamily: "Source Sans Pro, serif" 
});

export default muiTheme;