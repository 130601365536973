
const noLeadingSlash  = ( s ) => ( s.slice( 0) === '/' ? s.substring(1) : s );
const noTrailingSlash = ( s ) => ( s.slice(-1) === '/' ? s.slice(0,-1)  : s );

const pathNotEmpty = ( p ) => ( p ? ( p.length > 0 ? true : false ) : false );

const appHostNoSlash = noTrailingSlash( noLeadingSlash( process.env.REACT_APP_HOST ) );
const appPathNoSlash = noTrailingSlash( noLeadingSlash( process.env.REACT_APP_PATH ) );
const appBuildNoSlash = noTrailingSlash( noLeadingSlash( pathNotEmpty( process.env.REACT_APP_PATH_BUILD ) ? process.env.REACT_APP_PATH_BUILD : "build" ) );

const apiHostNoSlash = noTrailingSlash( noLeadingSlash( process.env.REACT_APP_API_HOST ) );
const apiPathNoSlash = noTrailingSlash( noLeadingSlash( process.env.REACT_APP_API_PATH ) );

const loginHostNoSlash = noTrailingSlash( noLeadingSlash( process.env.REACT_APP_HOST ) );
const loginPathNoSlash = noTrailingSlash( noLeadingSlash( process.env.REACT_APP_LOGIN_PATH ) );

export const loginServer 
	= process.env.REACT_APP_LOGIN_PROTOCOL + "://" + loginHostNoSlash + "/" + loginPathNoSlash;

export const appAddress
	= process.env.REACT_APP_PROTOCOL + "://" 
		+ appHostNoSlash
		+ ( pathNotEmpty( process.env.REACT_APP_PATH ) ? "/" + appPathNoSlash : "" );

export const apiServer 
	= process.env.REACT_APP_API_PROTOCOL + "://" 
		+ apiHostNoSlash
		+ ( pathNotEmpty( process.env.REACT_APP_API_PATH ) ? "/" + apiPathNoSlash : "" );

export const basepath 
	= "/" + ( pathNotEmpty( process.env.REACT_APP_PATH ) ? appPathNoSlash + "/" : "" )
        + appBuildNoSlash + "/";
/*
+ ( process.env.REACT_APP_PATH_BUILD 
    ? ( process.env.REACT_APP_PATH_BUILD.length > 0 
        ? process.env.REACT_APP_PATH_BUILD 
            + ( process.env.REACT_APP_PATH_BUILD.slice(-1) == '/' ? "" : "/" )
        : "build/" ) // unspecified? use "build"
    : "build/" ); // unspecified? use "build"
*/

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
// 
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

export const devEnvAuth = { "time":"2018-03-28T13:06:18.923578" ,
							"AUTH_TYPE":"Shibboleth",
							"CONTEXT_DOCUMENT_ROOT":"/var/www/html/",
							"CONTEXT_PREFIX":"",
							"DOCUMENT_ROOT":"/var/www/html/",
							"GATEWAY_INTERFACE":"CGI/1.1",
							"HTTP_ACCEPT":"text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
							"HTTP_ACCEPT_ENCODING":"gzip, deflate",
							"HTTP_ACCEPT_LANGUAGE":"en-US,en;q=0.9",
							"HTTP_CACHE_CONTROL":"max-age=0",
							"HTTP_CONNECTION":"keep-alive",
							"HTTP_COOKIE":"_shibsession_64656661756c7468747470733a2f2f72616e642e7374616e666f72642e656475=_4b8ddc4175389763a39def13f2b403fe",
							"HTTP_HOST":"ec2-52-35-167-104.us-west-2.compute.amazonaws.com",
							"HTTP_UPGRADE_INSECURE_REQUESTS":"1",
							"HTTP_USER_AGENT":"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.167 Safari/537.36",
							"PATH":"/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin",
							"QUERY_STRING":"",
							"REMOTE_ADDR":"73.223.88.205",
							"REMOTE_PORT":"57651",
							"REMOTE_USER":"morrowwr@stanford.edu",
							"REQUEST_METHOD":"GET",
							"REQUEST_SCHEME":"http",
							"REQUEST_URI":"/rss/rs4o/ssoapp/",
							"SCRIPT_FILENAME":"/var/www/html/rss/rs4o/ssoapp/index.cgi",
							"SCRIPT_NAME":"/rss/rs4o/ssoapp/index.cgi",
							"SERVER_ADDR":"172.30.0.171",
							"SERVER_ADMIN":"[no address given]",
							"SERVER_NAME":"ec2-52-35-167-104.us-west-2.compute.amazonaws.com",
							"SERVER_PORT":"80",
							"SERVER_PROTOCOL":"HTTP/1.1",
							"SERVER_SIGNATURE":"<address>Apache/2.4.18 (Ubuntu) Server at ec2-52-35-167-104.us-west-2.compute.amazonaws.com Port 80</address>",
							"SERVER_SOFTWARE":"Apache/2.4.18 (Ubuntu)",
							"Shib_Application_ID":"default",
							"Shib_Assertion_01":"http://localhost/Shibboleth.sso/GetAssertion?key=_4b8ddc4175389763a39def13f2b403fe&ID=_8990f517f64e532bc9fba977c4b24aae",
							"Shib_Assertion_Count":"01",
							"Shib_Authentication_Instant":"2018-03-28T13:07:26.309Z",
							"Shib_Authentication_Method":"urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport",
							"Shib_AuthnContext_Class":"urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport",
							"Shib_Identity_Provider":"https://idp.stanford.edu/",
							"Shib_Session_ID":"_4b8ddc4175389763a39def13f2b403fe",
							"Shib_Session_Index":"_ab106d617d1fd4693daaea7c54d6b7bb",
							"affiliation":"affiliate@stanford.edu;staff@stanford.edu;member@stanford.edu",
							"cn":"william ross morrow;william morrow",
							"displayName":"Dr. William Ross Morrow",
							"eppn":"morrowwr@stanford.edu",
							"givenName":"william",
							"mail":"morrowwr@stanford.edu",
							"sn":"morrow",
							"suAffiliation":"stanford:staff;stanford:student:nonactive;stanford:staff:nonactive",
							"suDisplayNameLF":"Morrow, Dr. William Ross",
							"uid":"morrowwr",
							"uid_alt":"morrowwr",
							"unscoped_affiliation":"member;staff;affiliate" };




