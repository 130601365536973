import React, { Component } from 'react';

import '../css/HelpFile.css'

// https://ctxhou.github.io/react-poppop/
// https://reactjsexample.com/a-mobile-support-and-multi-directional-modal-for-reactjs/
// npm install react-poppop --save
import PopPop from 'react-poppop';

class HelpFile extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        this.props.global.debugMsg('HelpFile.js : render()');
        return (
            <div>
                <PopPop position={this.props.global.helpFilePosition}
                        open={this.props.global.helpFile}
                        closeBtn={true}
                        closeOnEsc={true}
                        onClose={() => this.props.global.toggleHelp('helpFile', false)}
                        closeOnOverlay={true}>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="mediumpad">
                                <h3>File Upload Instructions</h3>
                                    <br />
                                    With M. Turk the 3rd, you can send MTurk workers a message, reward them a bonus, or assign an existing qualification to them by uploading a single, formatted Excel or CSV file. Note that to do either of these things, you must have done business with these workers on MTurk previously.<br />
                                    <br />
                                    The template for messaging, bonusing, and qualifying workers is different, but only in the names of the columns. You can use the Excel templates below to create a Message-style, Bonus-style, or Qualification-style Excel file. Once you upload an Excel file, the app should be able to determine whether it is a Message-style, Bonus-style, or Qualification-style task. Here are some examples of how the end files might look:<br />
                                    <br />
                                    
                                    <div className="row">
                                        <div className="col-xs-3 vcenter">
                                            <h4>Message Format</h4>
                                        </div>
                                        <div className="col-xs-9 vcenter">
                                            <a href="/templates/messages_template.xlsx" target="_blank"><input type="button" className="btn btn-primary btn-medium animated slideInRight" value="Download Message Template"></input></a>
                                        </div>
                                    </div>

                                    <pre>
                                        Subject,MessageText,WorkerId
                                        <br />
                                        "[GSB Behavioral Lab] You are invited to complete our HIT","Hi!. We have a brand new HIT that you are invited to participate in. Please have a look at http://www.link-to-hit.com",A1FBO95OVBYOH1
                                        <br />
                                        "[GSB Behavioral Lab] You are invited to complete our HIT","Hi!. We have a brand new HIT that you are invited to participate in. Please have a look at http://www.link-to-hit.com",A1F8RKCA3O05CP
                                        <br />
                                        "[GSB Behavioral Lab] You are invited to complete our HIT","Hi!. We have a brand new HIT that you are invited to participate in. Please have a look at http://www.link-to-hit.com",A1EITLFAMKA61U
                                    </pre>
                                    <br />

                                    <div className="row">
                                        <div className="col-xs-3 vcenter">
                                            <h4>Bonus Format</h4>
                                        </div>
                                        <div className="col-xs-9 vcenter">
                                            <a href="/templates/bonus_template.xlsx" target="_blank"><input type="button" className="btn btn-primary btn-medium animated slideInRight" value="Download Bonus Template"></input></a>
                                        </div>
                                    </div>

                                    <pre>
                                        AssignmentId,WorkerId,BonusAmount,Reason
                                        <br />
                                        34X6J5FLPTY6X3O35W8QWZKSWQ6QJD,A1EITLFAMKA61U,0.20,"Hi, you did a super duper job! Here is your $0.01 bonus."
                                        <br />
                                        36NEMU28XFD1I8QAK2KO4GUVOL9MW7,A1G9ZIBMV5PLE2,0.20,"You did a super duper job! Here is your $0.01 bonus."
                                        <br />
                                        38SKSKU7R1XZBVOKV9FU3C6Y95IILS,A22KRF782ELLB0,0.20,"You did a super duper job! Here is your $0.01 bonus."
                                    </pre>
                                    <br />

                                    <div className="row">
                                        <div className="col-xs-3 vcenter">
                                            <h4>Qualification Format</h4>
                                        </div>
                                        <div className="col-xs-9 vcenter">
                                            <a href="/templates/qualification_template.xlsx" target="_blank"><input type="button" className="btn btn-primary btn-medium animated slideInRight" value="Download Qualification Template"></input></a>
                                        </div>
                                    </div>

                                    <pre>
                                        WorkerId,QualificationTypeId,IntegerValue
                                        <br />
                                        A1EITLFAMKA61U,302A00XE7WQ1INQOY9A7NL1L05K5TX,100
                                        <br />
                                        A1G9ZIBMV5PLE2,302A00XE7WQ1INQOY9A7NL1L05K5TX,50
                                        <br />
                                        A22KRF782ELLB0,302A00XE7WQ1INQOY9A7NL1L05K5TX,0
                                    </pre>
                                </div>
                            </div>    
                        </div>
                </PopPop>
            </div>
        );
    }
}

export default HelpFile;