import React, { Component } from 'react';

import Header from './Header';

import MainPage from './MainPage';

export default class App extends Component {
	render() {
		return (
            <div>
              <Header />
              <MainPage />
            </div>
		);
	}
}
            
