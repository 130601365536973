import React, { Component } from 'react';
import PopPop from 'react-poppop';
import '../css/Submit.css'

class Submit extends Component {
    constructor(props) {
        super(props);

        ////////////////////////////////////////////////////////////////
        // local methods
        ////////////////////////////////////////////////////////////////
        this.bonusSubmitBoxBtns = this.bonusSubmitBoxBtns.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
        
        ////////////////////////////////////////////////////////////////
        // local component keys
        ////////////////////////////////////////////////////////////////
        this.state = {
            submitBtnToolTip: 'Please complete Steps 1, 2 and 3 first',
        }; 

        this.props.global.msgBoxState = false;
    }

    ////////////////////////////////////////////////////////////////
    // Bonus submit box user choice
    ////////////////////////////////////////////////////////////////
    bonusSubmitBoxBtns(event){
        let event_custom_attr = event.target.getAttribute('data');
        this.props.global.debugMsg('Submit: bonusSubmitBoxBtns(event) ==> ' + event_custom_attr);

        if (event_custom_attr.toLowerCase() === 'bonus-yes') {
            this.submitRequest(event);
        } else if (event_custom_attr.toLowerCase() === 'bonus-no') {
            // hide the submit box go back to the form
            this.props.global.showBonusSubmitBox = false;
            this.props.global.setSectionStates();
        }
    }


    ////////////////////////////////////////////////////////////////
    // submit request to backend server
    ////////////////////////////////////////////////////////////////
    submitRequest (event) {
        this.props.global.debugMsg('Submit: submitRequest()');
        let event_custom_attr = event.target.getAttribute('data');
        this.props.global.showBonusSubmitBox = false;
        
		if (this.props.global.dataFileType === this.props.global.fileTypes.MESSAGE || this.props.global.dataFileType === this.props.global.fileTypes.QUALIFICATION || event_custom_attr === 'bonus-yes'){
			let dataJson = {};
			// build the json data packet
			dataJson['awskeys'] = {'public':this.props.global.publicKey.toString(), 'private':this.props.global.privateKey.toString() };
			dataJson['email'] = this.props.global.userEmail.toString();
			dataJson['excel'] = this.props.global.dataFile;
			dataJson = JSON.stringify(dataJson);
			this.props.global.debugMsg(dataJson);

			let endPoint = this.props.global.nodeProtocol + '://' + this.props.global.nodeServer + ':' +  this.props.global.nodeServerPort + this.props.global.endPoints.SUBMIT;

			fetch(endPoint,
				{
					headers:{
						'Accept':'application/json',
						'Content-Type':'application/json',
					},
					mode:'cors',
					method: 'POST',
					body: dataJson
				})
				.then( response => response.json() )
				.then( (data) => {
					this.props.global.debugMsg(data);
					this.props.global.apiServerResponse = data;
				})
				.catch((error) => {
					console.error(JSON.stringify(error));
					this.props.global.apiServerResponse = error;
				});

				let title = 'Job Sent';
				let content = 'Your request has been submitted. You will recieve an email at ' + this.props.global.userEmail;
				let msgObj = {
				   'pos': 'centerCenter',
				   'state': 'show',
				   'title': title,
				   'content': [content],
				};

			// callback function when  close button is pressed; reset form
			this.props.global.msgBoxCallBack = function () { window.location.reload(); };  
			this.props.global.msgBoxBtnOpen(msgObj);
		}
		else {
			// Display message with Yes and No Box for Bonus
            this.props.global.showBonusSubmitBox = true;
            this.props.global.refreshPage();
		}
    }

    render() {
        this.props.global.debugMsg('Submit.js : render()');
        return (
            <div className="container container-margin">
            
                <div className="row">
                    <div className="col-xs-12">
                        <p><span><strong>STEP 4:</strong></span> Click "Submit"!</p>
                    </div>
                </div>
            
                <div className="row row-margin">
                    <div className="col-xs-12">
                        <div className="col-xs-4 vcenter">
                            <div title={this.state.submitBtnToolTip}>
                                <input type="submit" className="btn btn-primary btn-medium animated slideInRight" value="Submit" disabled={this.props.global.submitBtn} data="submit-btn" onClick={this.submitRequest}></input>
                            </div>
                        </div>        

                        <div className="col-xs-8 vcenter">
                        </div>
                    </div>
                </div>

                {/*////////////////////////////////////////////////////////////////*/}
                {/* PopUp Message Box - Only for Bonus*/}
                {/*////////////////////////////////////////////////////////////////*/}
                <div>
                    <PopPop position={this.props.global.showBonusSubmitBoxPos}
                        open={this.props.global.showBonusSubmitBox}
                        closeBtn={false}
                        closeOnEsc={false}
                        onClose={() => this.props.global.msgBoxBtnClose(this.props.global.emptyCallBackFunction,'showBonusSubmitBox')}
                        closeOnOverlay={true}
                        contentStyle={this.props.global.msgBoxContentStyleDefault}>

                        <div className="row row-margin">
                            <div className="col-xs-12">
                                <h3>Mturk Budget Confirmation</h3>
                                <hr></hr>
                                <p>This operation will cost approximately {this.props.global.bonusTotal} out of your Mturk balance of {this.props.global.acctBal}.
                                   <br/>
                                   Do you wish to proceed?
                                </p>
                                <hr></hr>
                            </div>
                        </div>

                        <div className="row row-margin">
                            <div className="col-xs-4">
                            </div>

                            <div className="col-xs-4">
                                {/*////////////////////////////////////////////////////////////////*/}
                                {/* submit bonus confirmation yes button */}
                                {/*////////////////////////////////////////////////////////////////*/}
                                <div id="upload-file-btns">
                                        <button type="button" className="btn btn-success btn-medium btn-block animated slideInRight" data="bonus-yes" onClick={(event) => this.bonusSubmitBoxBtns(event)}>Yes</button>
                                </div>
                            </div>

                            <div className="col-xs-4">
                                {/*////////////////////////////////////////////////////////////////*/}
                                {/* submit bonus confirmation no button */}
                                {/*////////////////////////////////////////////////////////////////*/}
                                <div id="upload-file-btns">
                                    <button type="button" className="btn btn-danger btn-medium btn-block animated slideInRight" data="bonus-no" onClick={(event) => this.bonusSubmitBoxBtns(event)}>No</button>
                                </div>
                            </div>
                        </div>
                    </PopPop>
                </div>

            </div>
        );
    }
}

export default Submit;
