import React, { Component } from 'react';

import '../css/IssueLinks.css'

class IssueLinks extends Component {
    render() {
        this.props.global.debugMsg('IssueLinks.js : render()');
        return (
            <div className="container container-margin">
            
                <div className="row">
                    <div className="col-xs-12">
                        <p><a href={this.props.global.issueLink} target="_blank" rel="noopener noreferrer">Click Here to Report an Issue</a></p>
                    </div>
                </div>            
            </div>
        );
    }
}

export default IssueLinks;