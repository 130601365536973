import React, { Component } from 'react';

// http://www.davidhu.io/react-spinners/
// npm install react-spinners --save
import { BarLoader } from 'react-spinners';

import '../css/Access.css'

class Access extends Component {
    constructor(props) {
        super(props);

        ////////////////////////////////////////////////////////////////
        // local methods
        ////////////////////////////////////////////////////////////////
        this.checkKeys = this.checkKeys.bind(this);
        this.checkKeysBtn = this.checkKeysBtn.bind(this);
        this.encryptKey = this.encryptKey.bind(this);
        this.decryptKey = this.decryptKey.bind(this);
        this.getFormKeys = this.getFormKeys.bind(this);
        this.setStateControlRender = this.setStateControlRender.bind(this);
        this.unlockCheckKeysBtn = this.unlockCheckKeysBtn.bind(this);

        ////////////////////////////////////////////////////////////////
        // local component keys
        ////////////////////////////////////////////////////////////////
        this.state = {
            accessMessage: '', // message from API after key validation
            backgroundColor: '',  // background color of message 
            checkKeysBtnToolTip: {'DISABLED':'Both keys required for validation', 'ENABLED':'Click to validate keys'},
            shouldRender: true, // flag to control if the component should render when the state changes
            showSpinner: true,  // show spinner
        }; 
    }

    ////////////////////////////////////////////////////////////////
    // API call to check AWS Keys
    ////////////////////////////////////////////////////////////////
    checkKeys(publicKey, privateKey) {
        this.props.global.debugMsg('Access: checkKeys()');

        // call backend api to validate keys
        let dataJson = {'apiKey':publicKey.toString(), 'secretKey':privateKey.toString()};
        dataJson = JSON.stringify(dataJson);
        let endPoint = this.props.global.nodeProtocol + '://' + this.props.global.nodeServer + ':' +  this.props.global.nodeServerPort + this.props.global.endPoints.CONFIRMKEYS;
        this.props.global.debugMsg(endPoint);
        this.props.global.debugMsg(dataJson);
        this.props.global.debugMsg(typeof dataJson);

        fetch(endPoint,
            {
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json',
            },
                mode:'cors',
                method: 'POST',
                body: dataJson
            })
            .then( response => response.json() )
            .then( (data) => {
                this.props.global.debugMsg(data);
                this.props.global.apiServerResponse = data;
            })
            .catch((error) => {
                console.error(JSON.stringify(error));
                this.props.global.apiServerResponse = error;
            });
        return true;
    }


    ////////////////////////////////////////////////////////////////
    // Check (AWS) Keys Button; validate keys
    ////////////////////////////////////////////////////////////////
    checkKeysBtn() {
        this.props.global.debugMsg('Access: checkKeysBtn()');

        // get the keys from the form fields, check if both keys are present and package into an array
        let formKeysArr = this.getFormKeys();

        // call API
        this.checkKeys(this.encryptKey('publicKey', formKeysArr[0]), this.encryptKey('privateKey', formKeysArr[1]));

        // show spinner while getting response from API
        this.props.global.showHideElement('access-spinner',true);
        this.props.global.showHideElement('access-message',false);
        this.props.global.showHideElement('acct-bal',false);

        this.props.global.sleep(1500).then(() => {
            this.props.global.showHideElement('access-spinner',false);
            let resObj = this.props.global.apiServerResponse;
            this.props.global.keysValidated = false;
            this.setStateControlRender('backgroundColor',this.props.global.backgroundColor.DANGER);

            if (resObj.status === 200) {
                this.setStateControlRender('accessMessage', resObj.description);
                // Make columns checks case sensitive
                if (this.state.accessMessage.match(new RegExp(this.props.global.regExAccess.INVALID, "i"))) {
                    this.setStateControlRender('backgroundColor', this.props.global.backgroundColor.DANGER);
                    this.props.global.keysValidated = false;
                } else {
                    // Account Keys valid.  Show Balance
                    this.setStateControlRender('backgroundColor', this.props.global.backgroundColor.SUCCESS);
                    this.props.global.keysValidated = true;
                    this.props.global.acctBal=this.props.global.formatNum(resObj.availableBalance);
                    this.props.global.showHideElement('acct-bal',true);
                }
            }
            else if (resObj.status !== 500){
                // server error non-200
                this.setStateControlRender('accessMessage', 'ERROR: ' + resObj.status + ' ' + resObj.description);
            } else {
                // server offline or non-responsive
                this.setStateControlRender('accessMessage', 'ERROR: 500 Server offline', true); // ok to render
            }
            this.props.global.showHideElement('access-message',true);
            this.props.global.setSectionStates();
        });
    }

    ////////////////////////////////////////////////////////////////
    // decrypt key (used for debugging)
    ////////////////////////////////////////////////////////////////
    decryptKey(encryptedData, decryptionKey) {
        this.props.global.debugMsg('Access: decryptKey()');
        return this.props.global.decrypt(encryptedData, decryptionKey);
    }

    ////////////////////////////////////////////////////////////////
    // encrypt data and save the key to props
    ////////////////////////////////////////////////////////////////
    encryptKey(keyName, keyValue, encryptionKey=this.props.global["encryptionKey"]) {
        this.props.global.debugMsg('Access: encryptKey()');

        // encrypt data
        let encryptedData = this.props.global.encrypt(keyValue, encryptionKey);
        this.props.global.saveToProps(keyName, encryptedData);

        // decrypt key for testing
        this.props.global.debugMsg(this.decryptKey(encryptedData, encryptionKey));

        return encryptedData;
    }

    ////////////////////////////////////////////////////////////////
    // return an array of only non-blank values. if both values are blank, return empty array
    ////////////////////////////////////////////////////////////////
    getFormKeys() {
        this.props.global.debugMsg('Access: getFormKeys()');
        let publicKey = document.getElementById('publicKey').value || undefined;
        let privateKey = document.getElementById('privateKey').value || undefined;
        let inputArr = [publicKey, privateKey];
        let keysArr = inputArr.filter(function(value, index, arr){ return value !== undefined;})
        return keysArr;
    }

    ////////////////////////////////////////////////////////////////
    // Set the state and control if the component should render or not
    ////////////////////////////////////////////////////////////////
    setStateControlRender(stateKey, stateValue, shouldRender=false) {
        this.props.global.debugMsg('Access: setStateControlRender(' + stateKey + ', ' + stateValue +')' );
        this.state[stateKey] = stateValue;
    }

    ////////////////////////////////////////////////////////////////
    // unlock the check keys button if both the aws access keys are entered
    ////////////////////////////////////////////////////////////////
    unlockCheckKeysBtn() {
        this.props.global.debugMsg('Access: unlockCheckKeysBtn()');

        this.props.global.checkSectionState('checkKeysBtn', this.getFormKeys().length === 2);

        // reset keys validation flag and hide the validation status message area
        this.props.global.keysValidated = false;
        this.props.global.showHideElement('access-message',false);
        this.props.global.showHideElement('acct-bal',false);

        this.props.global.setSectionStates();
    }

    render() {
        this.props.global.debugMsg('Access.js : render()'); 
        return (
            <div className="container container-margin">
            
                <div className="row">
                    <div className="col-xs-12">
                        <span><strong>STEP 1:</strong></span> Add in your AWS Access and Secret Keys
                    </div>
                </div>
            
                <div className="row row-margin">

                    {/*////////////////////////////////////////////////////////////////*/}
                    {/* AWS Public Access Key */}
                    {/*////////////////////////////////////////////////////////////////*/}
                    <div className="col-xs-4">
                        <div className="col-xs-5 vcenter">
                            <span>AWS Access Key</span>
                        </div>
                        
                        <div className="col-xs-7 vcenter">
                            <div className="bootstrap-filestyle input-group">
                                <input type="text" className="form-control " id="publicKey" placeholder="" disabled={this.props.global.publicKeyFormState} onChange={this.unlockCheckKeysBtn}></input>
                            </div>
                        </div>
                    </div>        
            
                    {/*////////////////////////////////////////////////////////////////*/}
                    {/* AWS Private Access Key */}
                    {/*////////////////////////////////////////////////////////////////*/}
                    <div className="col-xs-4">
                        <div className="col-xs-5 vcenter">
                            <span>AWS Secret Key</span>
                        </div>
                        <div className="col-xs-7 vcenter">
                            <div className="bootstrap-filestyle input-group">
                                <input type="text" className="form-control " id="privateKey" placeholder="" disabled={this.props.global.privateKeyFormState}  onChange={this.unlockCheckKeysBtn}></input>
                            </div>
                        </div>
                    </div>

                    {/*////////////////////////////////////////////////////////////////*/}
                    {/* Button: Check Keys */}
                    {/*////////////////////////////////////////////////////////////////*/}
                    <div className="col-xs-4">
                        <div className="col-xs-6 vcenter" title={this.props.global.checkKeysBtn === '' ? this.state.checkKeysBtnToolTip.ENABLED : this.state.checkKeysBtnToolTip.DISABLED}>
                            <input type="button" className="btn btn-primary btn-medium animated slideInRight" value="Check Keys" onClick={this.checkKeysBtn} disabled={this.props.global.checkKeysBtn}></input>
                        </div>

                        {/*////////////////////////////////////////////////////////////////*/}
                        {/* Help Icon */}
                        {/*////////////////////////////////////////////////////////////////*/}
                        <div className="col-xs-6 vcenter">
                            <span className="fa fa-question-circle fa-2x help-btn-cursor" onClick={() => this.props.global.toggleHelp('helpAccess', true)} aria-hidden="true"></span>
                        </div>
                    </div>        
                </div>

                {/*////////////////////////////////////////////////////////////////*/}
                {/* Spinner */}
                {/*////////////////////////////////////////////////////////////////*/}
                <div className="row row-margin" id="access-spinner">
                    <div className="col-xs-2"></div>
                    <div className="col-xs-2 text-right">
                        <span>Checking Keys...</span>
                    </div>
                    <div className="col-xs-6">
                        <BarLoader color={this.props.global.spinnerColor} height={this.props.global.spinnerHeight} width={this.props.global.spinnerWidth} loading={this.state.showSpinner}/>
                    </div>                    
                    <div className="col-xs-2"></div>
                </div>

                {/*////////////////////////////////////////////////////////////////*/}
                {/* Message Section */}
                {/*////////////////////////////////////////////////////////////////*/}
                <div className="row row-margin" id="access-message">
                    <div className="col-xs-2"></div>
                    <div className="col-xs-6" >
                        <div className="col-xs-4 text-right">Keys Validation Status:</div>
                        <div className="col-xs-6 text-center">
                            <div className={this.state.backgroundColor}>
                                <span>{this.state.accessMessage}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/*////////////////////////////////////////////////////////////////*/}
                {/* account balance */}
                {/*////////////////////////////////////////////////////////////////*/}
                <div className="row row-margin" id="acct-bal">
                    <div className="col-xs-2"></div>
                    <div className="col-xs-6" >
                        <div className="col-xs-4 text-right">Account Balance:</div>
                        <div className="col-xs-6 text-center">
                            <div className={this.state.backgroundColor}>
                                <span>{this.props.global.acctBal}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Access;
