
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { styles } from './../styles';

class Header extends Component {

  	render() {
    
	    return (

        <table style={{ marginTop : 20 , borderBottom : "1px solid #544948" }}>
        <tbody>
          <tr>
            <td style={{ fontSize : 18 }}>
            </td>
          </tr>
        </tbody>
        </table>

        );

    }

}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const mapStateToProps = ( state , props ) => { 
  return { user : state.AuthReducer.user.displayName };
}

export default connect( mapStateToProps )( Header );

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
