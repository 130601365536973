import React, { Component } from 'react';

// https://github.com/SheetJS/js-xlsx/tree/master/demos/react
// npm install xlsx --save
//import { XLSX } from 'xlsx';
import * as XLSX from 'xlsx/xlsx.mjs';

// https://github.com/agracio/ts-react-json-table
// npm install ts-react-json-table --save
import JsonTable  from 'ts-react-json-table';

// http://www.davidhu.io/react-spinners/
// npm install react-spinners --save
import { BarLoader } from 'react-spinners';

// https://ctxhou.github.io/react-poppop/
// https://reactjsexample.com/a-mobile-support-and-multi-directional-modal-for-reactjs/
// npm install react-poppop --save
// import PopPop from 'react-poppop';

import '../css/Upload.css'

class Upload extends Component {
    constructor(props) {
        super(props);

        ////////////////////////////////////////////////////////////////
        // local methods
        ////////////////////////////////////////////////////////////////
        this.clearFileInput = this.clearFileInput.bind(this);
        this.fileBtn = this.fileBtn.bind(this);
        this.ingestFile = this.ingestFile.bind(this);
        this.resetUpload = this.resetUpload.bind(this);
        this.setDataFileType = this.setDataFileType.bind(this);
        this.setSampleFileAttributes = this.setSampleFileAttributes.bind(this);
        this.showFile = this.showFile.bind(this);
        this.uploadBtn = this.uploadBtn.bind(this);
        this.uploadBtnApi =this.uploadBtnApi.bind(this);
        
        ////////////////////////////////////////////////////////////////
        // local component keys
        ////////////////////////////////////////////////////////////////
        this.state = {
            dataSample: [],  // sample excel or csv to display when it is uploaded
            loading: true,  // controlls when spinner is displayed
            fileTotalRows: 0, // total rows read
            fileShowRows: 0, // rows of file displayed
            nbrSampleRows: 10,  // number of rows from the excel or csv file to display as a sample index 0 based
            styleInputFile: {'position':'absolute', 'clip':'rect(0px, 0px, 0px, 0px)'},  // round corners on input file text box
        }; 
    }

    ////////////////////////////////////////////////////////////////
    // clear the file input text box when choose file button is clicked
    // this will force the onchange to fire even if the same file is chosen
    ////////////////////////////////////////////////////////////////
    clearFileInput(event) {
        this.props.global.debugMsg('Upload: clearFileInput(event)');
        event.target.value = null;
    }

    ////////////////////////////////////////////////////////////////
    // user choice if uploaded file is correct or not
    ////////////////////////////////////////////////////////////////
    fileBtn(event) {
        this.props.global.debugMsg('Upload: fileBtn(event)');
        let event_custom_attr = event.target.getAttribute('data');
        this.props.global.debugMsg('Upload: fileBtn(' +  event_custom_attr + ')');
        let msgObj = {'pos':'centerCenter','state':'show'};

        if (event_custom_attr.toLowerCase() === 'yes') {
            this.props.global.fileOk = true;
            // hide the button section
            this.props.global.showHideElement("check-file-buttons", false);
            this.props.global.setSectionStates();
        } else if (event_custom_attr.toLowerCase() === 'no') {
            msgObj['title'] = 'Fix Issues';
            msgObj['content'] = 'Please fix your issues and then choose another file to upload.';

            // set callback function when close button is pressed
            this.props.global.msgBoxCallBack = this.resetUpload;

            this.props.global.debugMsg(msgObj);
            this.props.global.msgBoxBtnOpen(msgObj);
        }
    }

    ////////////////////////////////////////////////////////////////
    // ingest the uploaded excel or csv file and convert it to JSON
    ////////////////////////////////////////////////////////////////
    ingestFile(fileObject) {
        this.props.global.debugMsg('Upload: ingestDataFile()');

        this.props.global.fileOk = false;
        this.props.global.saveToProps('fileObject', fileObject);

        // no file selected or cancel button was clicked. do not ingest
        if (typeof fileObject === "undefined") {
            this.props.global.setSectionStates();
            return true;
        }
        
        let reader = new FileReader();  
        reader.readAsArrayBuffer(fileObject);

        reader.onload = function (event) {
            let data = new Uint8Array(reader.result);
            let wb = XLSX.read(data, {
                type: 'array'
            });

            // Get worksheet
            let first_sheet_name = wb.SheetNames[0];
            let worksheet = wb.Sheets[first_sheet_name];
            let jsonExcelDataRaw = XLSX.utils.sheet_to_json(worksheet, { raw: false });

            this.props.global.saveToProps('dataFile', jsonExcelDataRaw);

            this.state.fileTotalRows = this.props.global.formatNum(this.props.global.dataFile.length);
            this.state.fileShowRows = this.state.fileTotalRows < 10 ? this.state.fileTotalRows : 10;

            // get the column names in the JSON object and combine them into a string to search for specific keywords
            let colNamesArr = Object.keys(jsonExcelDataRaw.slice(0,1)[0]);
            this.state.dataColumns = colNamesArr;
            let colNameStr = colNamesArr.join(",");

            this.setDataFileType(colNameStr);

            this.props.global.debugMsg('dataSample');
            this.props.global.debugMsg(jsonExcelDataRaw.slice(0, this.state.nbrSampleRows));

            // use setState to trigger render method in all the components aka refresh page
            // so sample data will show when the upload button is pressed
            this.setState({'dataSample': jsonExcelDataRaw.slice(0, this.state.nbrSampleRows)});
        }.bind(this);
    }

    ////////////////////////////////////////////////////////////////
    // reset the upload section
    ////////////////////////////////////////////////////////////////
    resetUpload(){
        this.props.global.debugMsg('Upload: resetUpload()');

        this.props.global.chooseFileBtn = this.props.global.buttonState.ENABLED;
        this.props.global.uploadBtn = this.props.global.buttonState.DISABLED;

        this.props.global.fileName = '';
        this.props.global.dataFile = '';
        this.props.global.dataFileType = '';

        this.props.global.toggleDisplayAttribute("check-file-buttons", "block");  // show file button sub-section in data section
        this.props.global.toggleDisplayAttribute("data-file-content", "none");  // hide whole data section
        
        this.props.global.fileOk = false;        
        this.props.global.fileObject = new Object();

        this.props.global.setSectionStates();
    }

    ////////////////////////////////////////////////////////////////
    // set the upload data file type: bonus, message, possible bonus or message, or error
    ////////////////////////////////////////////////////////////////
    setDataFileType(colNameStr) {
        let colNameStrNew = ',' + colNameStr + ',';
        this.props.global.debugMsg('Upload: setDataFileType(' + colNameStr + ')');
        // set default to unknown
        let fileType = 'UNKNOWN';
        let typeCols = this.props.global.regFileTypeCols;
        let colCount = [];

        let unrecognizedCols = {};

        for (var i in typeCols) {
            for (var key in typeCols[i]){
                if (colCount[key] === undefined) {
                    colCount[key] = 0;
                }
                for (var j in typeCols[i][key]) {
                    // this.props.global.debugMsg(typeCols[i][key][j]['col'] + ' => ' + typeCols[i][key][j]['weight']);
                    // find matches in column string from file
                    let currCol = typeCols[i][key][j]['col'];
                    let typeColsPadded = ',' + currCol + ','; 

                    //this.props.global.debugMsg("Matching: " + key + " in " + colNameStrNew + " to " + typeColsPadded);
                    // case sensitive; exact match
                    if (colNameStrNew.match(new RegExp(typeColsPadded))) {
                        colCount[key] += typeCols[i][key][j]['weight'];
                    }
                    else {
                        // column not found. build a list of unmatched columns
                        if (!(key in unrecognizedCols)) {
                            unrecognizedCols[key] = [];
                        }
                        unrecognizedCols[key].push(currCol);
                    }
                }
            }
        }

        this.props.global.debugMsg('Bonus weight => ' + colCount[this.props.global.fileTypes.BONUS]);
        this.props.global.debugMsg('Message weight => ' + colCount[this.props.global.fileTypes.MESSAGE]);
        this.props.global.debugMsg('Qualification weight => ' + colCount[this.props.global.fileTypes.QUALIFICATION]);

        // set file type
        fileType = this.props.global.fileTypes.UNKNOWN;
        if (colCount[this.props.global.fileTypes.BONUS] === this.props.global.fileTypesColSum.BONUS) {
            fileType = this.props.global.fileTypes.BONUS;
	    let bonusTotal = 0;
	    for (let i = 0; i < this.props.global.dataFile.length; i++) {
                bonusTotal += parseFloat(this.props.global.dataFile[i]["BonusAmount"]);
            }
	    this.props.global.bonusTotal = 1.2*bonusTotal;
        } else if (colCount[this.props.global.fileTypes.MESSAGE] === this.props.global.fileTypesColSum.MESSAGE) {
            fileType = this.props.global.fileTypes.MESSAGE;
        } else if (colCount[this.props.global.fileTypes.QUALIFICATION] === this.props.global.fileTypesColSum.QUALIFICATION) {
            fileType = this.props.global.fileTypes.QUALIFICATION;
        } else if (colCount[this.props.global.fileTypes.BONUS] > colCount[this.props.global.fileTypes.MESSAGE]) {
            fileType = 'possibly a ' + this.props.global.fileTypes.BONUS;
        } else if (colCount[this.props.global.fileTypes.MESSAGE] > colCount[this.props.global.fileTypes.BONUS]) {
            fileType = 'possibly a ' + this.props.global.fileTypes.MESSAGE;
        }

        this.props.global.saveToProps('dataFileType', fileType);
    }

    ////////////////////////////////////////////////////////////////
    // set sample data file div attributes
    ////////////////////////////////////////////////////////////////
    setSampleFileAttributes(show_file_btns="none", show_content="none") {
        this.props.global.debugMsg('Upload: setSampleFileAttributes(' + show_file_btns + ', ' + show_content + ')');
        this.props.global.toggleDisplayAttribute("upload-file-btns", show_file_btns);
        this.props.global.toggleDisplayAttribute("data-file-content", show_content);
    }

    ////////////////////////////////////////////////////////////////
    // show the filename in the text box and unlock the Upload File button
    ////////////////////////////////////////////////////////////////
    showFile(event) {
        this.props.global.debugMsg('Upload: showFile()');

        let fileName = String(event.target.value).split("\\").pop();
        this.props.global.saveToProps('fileName', fileName);

        this.props.global.setSectionStates();

        // reset sample data display
        this.setSampleFileAttributes();

        this.ingestFile(event.target.files[0]);
    }

    ////////////////////////////////////////////////////////////////
    // UPLOAD BUTTON
    ////////////////////////////////////////////////////////////////
    uploadBtn() {
        this.props.global.debugMsg('Upload: uploadBtn()');

        // reset upload file variables, submit button state and hide file table and buttons
        this.props.global.fileOk = false;
        this.props.global.toggleDisplayAttribute("check-file-buttons", "block");  // show file button sub-section in data section
        this.props.global.toggleDisplayAttribute("data-file-content", "none");

        this.props.global.showHideElement('upload-spinner',true);

        this.props.global.sleep().then(() => {
            this.props.global.showHideElement('upload-spinner',false);

            // show the specific set of buttons based on uploaded or ingested file type
            if (this.props.global.dataFileType === this.props.global.fileTypes.BONUS || this.props.global.dataFileType === this.props.global.fileTypes.MESSAGE || this.props.global.dataFileType === this.props.global.fileTypes.QUALIFICATION) {
                this.setSampleFileAttributes("block", "block");
                this.props.global.setSectionStates();
            } else {
                // file type not recognized; do not display sample file div
                this.setSampleFileAttributes("none", "block");

                // hide the button section
                this.props.global.showHideElement("check-file-buttons", false);

                let msgObj = {
                    'pos':'centerCenter',
                    'state':'show',
                    'title':'Fix Issues',
                    'content':'Please fix your issues and then choose a file to upload.'
                };
                // set callback function when close button is pressed
                this.props.global.msgBoxCallBack = this.props.global.emptyCallBackFunction;

                // cause the onchange on file input text field the to fire
                this.props.global.fileName = null;

                this.props.global.msgBoxBtnOpen(msgObj);
            }
        });
    }

    ////////////////////////////////////////////////////////////////
    // UPLOAD BUTTON API TEST
    ////////////////////////////////////////////////////////////////
    uploadBtnApi() {
        this.props.global.debugMsg('Upload: uploadBtnApi()');

        let dataJson = JSON.stringify(this.props.global.dataFile);
        let endPoint = 'http://' + this.props.global.nodeServer + ':' +  this.props.global.nodeServerPort + this.props.global.endPoints.EXCEL;

        this.props.global.debugMsg(endPoint);
        this.props.global.debugMsg(dataJson);
        this.props.global.debugMsg(typeof dataJson);

        fetch(endPoint,
        {
            headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            },
            mode:'cors',
            method: 'POST',
            body: dataJson
        })
        .then( response => response.json() )
        .then( (data) => {
            this.props.global.debugMsg(data);
            this.state.apiServerResponse = data;
        })
        .catch((error) => {
            console.error(JSON.stringify(error));
            this.state.apiServerResponse = error;
        });
    }

    render() {
        this.props.global.debugMsg('Upload: render()');
        return (
            <div className="container container-margin">

                <div className="row">
                    <div className="col-xs-9">
                        <p><span><strong>Step 2:</strong></span> Upload an XLS or XLSX file in Bonus, Message, or Qualification Style</p>
                    </div>
                </div>
        
                <div className="row">
                    <div className="col-xs-7">
                        {/*////////////////////////////////////////////////////////////////*/}
                        {/* upload text box and choose file button */}
                        {/*////////////////////////////////////////////////////////////////*/}
                        <input type="file" className="filestyle" name="filecsv" accept=".csv,.xls,.xlsx" id="choosefile" tabindex="-1" style={this.state.styleInputFile} onClick={this.clearFileInput} onChange={this.showFile}></input>
                        <div className="bootstrap-filestyle input-group">
                            <input type="text" className="form-control" placeholder="" disabled="1" value={this.props.global.fileName}></input>
                            <span className="group-span-filestyle input-group-btn" tabindex="0">					
                                <label for="choosefile" className="btn btn-default" disabled={this.props.global.chooseFileBtn}>
                                    <span className="icon-span-filestyle glyphicon glyphicon-folder-open"></span>
                                    <span className="buttonText">&nbsp;&nbsp;Choose file</span>
                                </label>
                            </span>
                        </div>
                    </div>

                    {/*////////////////////////////////////////////////////////////////*/}
                    {/* upload file button */}
                    {/*////////////////////////////////////////////////////////////////*/}
                    <div className="col-xs-2">
                        <input type="button" className="btn btn-primary btn-medium animated slideInRight" value="Upload File" onClick={this.uploadBtn} disabled={this.props.global.uploadBtn}></input>
                    </div>

                    {/*////////////////////////////////////////////////////////////////*/}
                    {/* Test API Button (development) only */}
                    {/*////////////////////////////////////////////////////////////////*/}
                    {/*
                    <div className="col-xs-2">
                        <input type="button" className="btn btn-primary btn-medium animated slideInRight" value="API Test" onClick={this.uploadBtnApi}></input>
                    </div>
                    */}
            
                    {/*////////////////////////////////////////////////////////////////*/}
                    {/* help icon  */}
                    {/*////////////////////////////////////////////////////////////////*/}
                    <div className="col-xs-1 micropad" >
                        <span className="fa fa-question-circle fa-2x help-btn-cursor" id="upload-help-csv-btn" onClick={() => this.props.global.toggleHelp('helpFile', true)} aria-hidden="true"></span>
                    </div>
                </div>

                {/*////////////////////////////////////////////////////////////////*/}
                {/* Spinner */}
                {/*////////////////////////////////////////////////////////////////*/}
                <div className="row row-margin" id="upload-spinner">
                    <div className="col-xs-2"></div>
                    <div className="col-xs-2 text-right">
                        <span>Uploading File...</span>
                    </div>
                    <div className="col-xs-6">
                        <BarLoader color={this.props.global.spinnerColor} height={this.props.global.spinnerHeight} width={this.props.global.spinnerWidth} loading={this.state.loading}/>
                    </div>                    
                    <div className="col-xs-2"></div>
                </div>

                {/*////////////////////////////////////////////////////////////////*/}
                {/* show Sample File Content Section */}
                {/*////////////////////////////////////////////////////////////////*/}
                <div className="row" id="data-file-content">
                    <div className="col-xs-12">
                        <div className="row row-margin">
                            <div className="col-xs-1 bg-background"></div>
                            <div className="col-xs-8">
                                <p>First {this.state.fileShowRows} rows of <span><strong>{this.state.fileTotalRows}</strong></span> rows read in <span><strong>{this.props.global.dataFileType}</strong></span> file type.
                                  <span>  { this.props.global.dataFileType === this.props.global.fileTypes.UNKNOWN || this.props.global.dataFileType.toLowerCase().startsWith(this.props.global.fileTypes.POSSIBLE.toLowerCase()) ? 'Fix file and reupload.' :  'Does the information below look correct?'}</span>
                                </p>
                            </div>
                            <div className="col-xs-1">
                            </div>
                        </div>

                        <div className="row row-margin" id="check-file-buttons">

                            <div className="col-xs-3"></div>

                            <div className="col-xs-6">
                                {/*////////////////////////////////////////////////////////////////*/}
                                {/* file yes no buttons */}
                                {/*////////////////////////////////////////////////////////////////*/}
                                <div id="upload-file-btns">
                                    <div className="col-xs-3">
                                        <button type="button" className="btn btn-success btn-medium btn-block animated slideInRight" data="yes" onClick={(event) => this.fileBtn(event)}>Yes</button>
                                    </div>

                                    <div className="col-xs-3">
                                        <button type="button" className="btn btn-danger btn-medium btn-block animated slideInRight" data="no" onClick={(event) => this.fileBtn(event)}>No</button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-2"></div>
                        </div>

                        {/*////////////////////////////////////////////////////////////////*/}
                        {/* Display Sample Data Table from File */}
                        {/*////////////////////////////////////////////////////////////////*/}
                        <div className="row row-margin" id="displaySampleData">
                            <div className="col-xs-9 hscrollbar smallFont">
                                <JsonTable className="table" rows={this.state.dataSample} />
                            </div>
                            <div className="col-xs-3"></div>
                        </div>
                    </div>
                </div>

			</div>
        );
    }

}
export default Upload;
