import React, { Component } from 'react';

import '../css/MainPage.css'

import Access from './Access'  // AWS Keys Section
import Upload from './Upload'  // Upload Data File Section
import Email from './Email'  // Email Section
import Submit from './Submit'  // Submit Section
import IssueLinks from './IssueLinks'  // Submit Section
import MessageBox from './MessageBox'  // Message Box Section

// help pages
import HelpFile from './HelpFile'
import HelpAccess from './HelpAccess'

// encryption and decryption library
// npm install crypto-js --save
// https://github.com/z-hao-wang/react-native-rsa
import CryptoJS from 'crypto-js';

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ////////////////////////////////////////////////////////////////
      // functions that can be used in other components
      ////////////////////////////////////////////////////////////////
      checkChooseFileBtn: this.checkChooseFileBtn.bind(this),
      checkSectionState: this.checkSectionState.bind(this),
      checkSubmit: this.checkSubmit.bind(this),
      convertBoolean: this.convertBoolean.bind(this),
      debugMsg: this.debugMsg.bind(this),
      decrypt: this.decrypt.bind(this),
      emptyCallBackFunction: this.emptyCallBackFunction.bind(this),
      encrypt: this.encrypt.bind(this),
      formatNum: this.formatNum.bind(this),
      msgBoxBtnOpen: this.msgBoxBtnOpen.bind(this),
      msgBoxBtnClose: this.msgBoxBtnClose.bind(this),
      refreshPage: this.refreshPage.bind(this),
      saveToProps: this.saveToProps.bind(this),
      setSectionStates: this.setSectionStates.bind(this),
      setStateNoRender: this.setStateNoRender.bind(this),
      setStyleAttribute: this.setStyleAttribute.bind(this),
      showHideElement: this.showHideElement.bind(this),
      sleep: this.sleep.bind(this),
      toggleDisplayAttribute: this.toggleDisplayAttribute.bind(this),
      toggleHelp: this.toggleHelp.bind(this),
      
      ////////////////////////////////////////////////////////////////
      // non specific component keys
      ////////////////////////////////////////////////////////////////
      debugFlag: process.env.REACT_APP_DEBUG_FLAG,  // debuggins flag to turn on or off debugging messages.
      refresh: 'Y',  // dummy key used to refresh the page
      sleepDelay: 1500, // delay in ms
      spinnerColor: '#123abc',  // dark blue
      spinnerHeight: 16, // spinner height in px
      spinnerWidth: 150, // spinner width in px
      backgroundColor: {'SUCCESS':'bg-success', 'INFO':'bg-info','WARNING':'bg-warning','DANGER':'bg-danger'},  // bootstrap 3 color enumeration
      buttonState: {'ENABLED':'','DISABLED':'DISABLED'},  // values for the disabled attribute on tags

      ////////////////////////////////////////////////////////////////
      // important step flags
      ////////////////////////////////////////////////////////////////
      keysValidated: false, // set flag to true if both keys have been validated through an api call on the server
                            // if the keys change after his has been set, change back to false
      fileOk: false,        // set flag to true if the user has uploaded and press the 'yes' button that the file is correct
                            // if the user selects a new file after after his has been set, change back to false
      emailOk: false,       // email section status flag. set to true when both email and verified email are the same and using a correct email format
      submitBtnMsgDisplayed: false,  // flag to controls when the submit button message has been display to prevent multiple pop-up messages. 

      ////////////////////////////////////////////////////////////////
      // Access Component Keys
      ////////////////////////////////////////////////////////////////
      checkKeysBtn: 'disabled',  // button state: non-blank => disabled, blank => enabled
      checkKeysMinLen: 8,  // minimum number of characters of form field checking routines to fire
      encryptionKey: process.env.REACT_APP_ENCRYPTION_KEY, //  from .env file
      endPoints: {'CONFIRMKEYS':process.env.REACT_APP_API_CONFIRMKEYS,
                  'EXCEL':process.env.REACT_APP_API_EXCEL,
                  'SUBMIT':process.env.REACT_APP_API_SUBMIT
                },  // end point enumeration
      privateKey: '', // AWS private key (encrypted)
      privateKeyFormState: '',  // private key form text box state (unused): non-blank => disabled, blank => enabled
      publicKey: '',  // AWS public key (encrypted)
      publicKeyFormState: '',  // public key form text box state (unused): non-blank => disabled, blank => enabled 
      regExAccess:{'INVALID':'error'},  // enumeration: Regular Expression to determine if the AWS keys are invalid

      ////////////////////////////////////////////////////////////////
      // Upload Component Keys
      ////////////////////////////////////////////////////////////////
      chooseFileBtn: 'disabled', // button state: non-blank => disabled, blank => enabled
      dataFile: '',  // contains the json of the upload data file
      dataFileType: '',  // Bonus or Message or Qualification
      fileName: '',  // csv upload file name
      fileObject: '', // selected file object, event.target.files[0], when file is chosen
      fileTypes: {'BONUS':'BONUS','MESSAGE':'MESSAGE','QUALIFICATION':'QUALIFICATION','UNKNOWN':'UNKNOWN','POSSIBLE':'POSSIBLY'},  // file types enumeration
      fileTypesColSum: {'BONUS':13,'MESSAGE':12,'QUALIFICATION':14},  // total weights of each file type based on the total sum of each column
      regFileTypeCols: [{'BONUS':[{'col':'AssignmentId', 'weight':1},
                                  {'col':'WorkerId', 'weight':1},
                                  {'col':'BonusAmount', 'weight':10},
                                  {'col':'Reason','weight':1}]},
                     {'MESSAGE':[{'col':'Subject', 'weight':1},
                                 {'col':'MessageText', 'weight':10},
                                 {'col':'WorkerId','weight':1}]},
                     {'QUALIFICATION':[{'col':'WorkerId', 'weight':1},
                                       {'col':'QualificationTypeId', 'weight':10},
                                       {'col':'IntegerValue','weight':3}]}],
                     // structure to check the columns in each file type and assign a weight to the column if found
      uploadBtn: 'disabled',  // button state: non-blank => disabled, blank => enabled

      ////////////////////////////////////////////////////////////////
      // Email Component Keys
      ////////////////////////////////////////////////////////////////
      emailCheckMinLen: 10,  // minimum number of characters of form field checking routines to fire
      emailInputFormState: 'disabled', // state of the email input form: non-blank => disabled, blank => enabled
      emailInputFormVerificationState: 'disabled', // state of the verification email input form: non-blank => disabled, blank => enabled
      userEmail: '', // email


      ////////////////////////////////////////////////////////////////
      // Submit Component Keys
      ////////////////////////////////////////////////////////////////
      submitBtn: 'disabled', // button state: non-blank => disabled, blank => enabled
      bonusTotal: 0, // the total of a bonus

      ////////////////////////////////////////////////////////////////
      // IssueLinks Component Keys
      ////////////////////////////////////////////////////////////////
      issueLink: 'https://code.stanford.edu/gsb-circle-research/mt3/issues',
      
      ////////////////////////////////////////////////////////////////
      // API Server Response Keys
      ////////////////////////////////////////////////////////////////
      apiServerError: '__BLANK__', // API server error return
      apiServerResponse: '__BLANK__',  // API server response

      ////////////////////////////////////////////////////////////////
      // Help File Component Keys
      ////////////////////////////////////////////////////////////////
      helpFile: false,
      helpFileMsgBoxPos: 'centerCenter', 

      ////////////////////////////////////////////////////////////////
      // Help Access Component
      ////////////////////////////////////////////////////////////////
      helpAccess: false,
      helpAccessMsgBoxPos: 'centerCenter', 

      ////////////////////////////////////////////////////////////////
      // Message Box Component Keys
      ////////////////////////////////////////////////////////////////
      msgBoxPosList: ['topLeft','topCenter','topRight','centerLeft','centerCenter','centerRight','bottomLeft', 'bottomCenter', 'bottomRight'],
      msgBoxState:'', // message box state; 'True' = show; 'False' = hide
      showMsgBox: '', // state message box. show or no show

      msgBoxPos: '',  // message box position
      msgBoxTitle: '', // message box title
      msgBoxContent: '', // message box content
      msgBoxCallBack: '', // call callback function when the message box is closed.
                          // used for any special processing after the message box is closed

      ////////////////////////////////////////////////////////////////
      // BACK-END SERVER
      ////////////////////////////////////////////////////////////////
      nodeProtocol: process.env.REACT_APP_API_PROTOCOL,  //  from .env file
      nodeServer: process.env.REACT_APP_API_SERVER,  //  from .env file
      nodeServerPort: process.env.REACT_APP_API_SERVER_PORT, //  from .env file
    };
  }

  ////////////////////////////////////////////////////////////////
  // Unlock the choose file button if AWS Keys are valid
  ////////////////////////////////////////////////////////////////
  checkChooseFileBtn() {
    if (this.state.keysValidated || this.state.fileOk) {
      this.state.chooseFileBtn = '';  // enable button

      // enable button if a file is selected
      if (this.state.fileName !== '') {
        this.state.uploadBtn = '';
      }
      this.debugMsg('MainPage: checkChooseFileBtn() => enabled');
    } else {
      this.state.chooseFileBtn = 'disable';  // disable button
      this.state.uploadBtn = 'disable';  // disable button      
      this.debugMsg('MainPage: checkChooseFileBtn() => disabled');
    }
  }

  ////////////////////////////////////////////////////////////////
  // Unlock form element if prerequisites met
  ////////////////////////////////////////////////////////////////
  checkSectionState(formElement, booleanCheck) {
    this.debugMsg('MainPage: checkSectionState(' + formElement + ')');
    this.state[formElement] = 'disable';  // default disable
    if (booleanCheck) {
      this.state[formElement] = '';  // enable
    }
    this.debugMsg('MainPage: checkSectionState(' + formElement + ' => ' +  (this.state[formElement] === '' ? 'enabled' : 'disabled') + ')');
  }

  ////////////////////////////////////////////////////////////////
  // Unlock Submit button if prerequisites met
  ////////////////////////////////////////////////////////////////
  checkSubmit() {
    this.debugMsg('MainPage: checkSubmit()');
    this.checkSectionState('submitBtn', this.state.keysValidated && this.state.fileOk);
  }

  ////////////////////////////////////////////////////////////////
  // convert boolean to True/False
  ////////////////////////////////////////////////////////////////
  convertBoolean(boolean) {
    return boolean ? 'True' : 'False';
  }

  ////////////////////////////////////////////////////////////////
  // Debugging. Show console log
  ////////////////////////////////////////////////////////////////
  debugMsg(msg) {
    if (this.state.debugFlag === "YES"){
      console.log(msg);
    }
  }

  ////////////////////////////////////////////////////////////////
  // Decrypt Data
  ////////////////////////////////////////////////////////////////
  decrypt(entryptedData, encryptionKey) {
    let bytes = CryptoJS.AES.decrypt(entryptedData.toString(), encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  ////////////////////////////////////////////////////////////////
  // Empty Function; Used in close button event in message box component
  ////////////////////////////////////////////////////////////////
  emptyCallBackFunction() {
    this.debugMsg('MainPage: emptyCallBackFunction()');
  }

  ////////////////////////////////////////////////////////////////
  // Encrypt Data
  ////////////////////////////////////////////////////////////////
  encrypt(UnencryptedData, encryptionKey) {
    this.debugMsg('MainPage: encrypt()');
    return CryptoJS.AES.encrypt(UnencryptedData, encryptionKey);
  }

  ////////////////////////////////////////////////////////////////
  // format number and show currency symbol
  ////////////////////////////////////////////////////////////////
  formatNum(num, showCurrencySymbol=false){
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits:0,
      // the default value for minimumFractionDigits depends on the currency
      // and is usually already 2
    });

    let NumFormatted =  formatter.format(num);
    if (!showCurrencySymbol) {
      NumFormatted = NumFormatted.replace("$","");
    }
    this.debugMsg('MainPage: formatNum(' + num + ', ' + this.state.convertBoolean(showCurrencySymbol) + ') ==> ' + NumFormatted);
    return NumFormatted;
  }


  ////////////////////////////////////////////////////////////////
  // common message box
  // object properties
  // pos: string : position from list, msgBoxPos
  // state: string : show or hide
  // title: string : message title
  // content : string : message content
  ////////////////////////////////////////////////////////////////
  msgBoxBtnOpen(msgObj) {
    this.debugMsg('MainPage: msgBoxBtnOpen()');

    this.state.msgBoxPos = this.state.msgBoxPosList.includes(msgObj.pos) ? msgObj.pos : 'topCenter';

    this.state.msgBoxState = (msgObj.state.toLowerCase() === 'show') ? true : false;
    this.state.msgBoxTitle = msgObj.title;
    this.state.msgBoxContent = msgObj.content;
    
    this.state.refreshPage();
  }

  ////////////////////////////////////////////////////////////////
  // close message box and invoke callback function
  ////////////////////////////////////////////////////////////////
  msgBoxBtnClose(callback=function(){}) {
    this.debugMsg('MainPage: msgBoxBtnClose()');
    this.state.msgBoxState = false;
    callback();
    this.state.refreshPage();
  }

  ////////////////////////////////////////////////////////////////
  // refresh the render() method
  ////////////////////////////////////////////////////////////////
  refreshPage() {
    // use the setState to refresh the render() method in each component.  Any key/value pair will do
    this.setState({'refresh': 'Y'});
  }

  ////////////////////////////////////////////////////////////////
  // add key to props
  ////////////////////////////////////////////////////////////////
  saveToProps(stateKey="__blank__", stateValue="") {
    if (stateKey !== "__blank__") {
      this.state[stateKey] = stateValue;
      var showStateValue = ( stateValue === "" ) ? '""' : stateValue;
      this.debugMsg('MainPage: saveStateToProps(' + stateKey + ', ' + showStateValue + ')');
    }
  }

  ////////////////////////////////////////////////////////////////
  // Unlock section and form elements if prerequisites are met
  ////////////////////////////////////////////////////////////////
  setSectionStates() {
    this.debugMsg('MainPage: setSectionStates()');

    // upload file section elements
    this.checkSectionState('chooseFileBtn', this.state.keysValidated || this.state.fileOk);
    this.checkSectionState('uploadBtn', this.state.fileName.length > 0 || this.state.fileOk);

    // email section elements
    this.checkSectionState('emailInputFormState', (this.state.keysValidated && this.state.fileOk) || this.state.emailOk || this.state.userEmail);

    // submit section elements
    this.checkSectionState('submitBtn', this.state.keysValidated && this.state.fileOk && this.state.emailOk);

    // form requirements met. pop up submit
    if ( (this.state.keysValidated && this.state.fileOk && this.state.emailOk) && !this.state.submitBtnMsgDisplayed) {
      this.state.submitBtnMsgDisplayed = true;

      let msgObj = {
        'pos':'centerCenter',
        'state':'show',
        'title':'All Requirements Met',
        'content':'The Submit button at the bottom of this form is now enabled!'
      };
      // set callback function when close button is pressed
      this.state.msgBoxCallBack = this.state.emptyCallBackFunction;      
      this.state.msgBoxBtnOpen(msgObj);

      return true;
    } else if ( (!this.state.keysValidated || !this.state.fileOk || !this.state.emailOk) && this.state.submitBtnMsgDisplayed) {
      this.state.submitBtnMsgDisplayed = false;
    }
    this.refreshPage();
    
  }

  ////////////////////////////////////////////////////////////////
  // Set the state without firing a re-render (TBD)
  ////////////////////////////////////////////////////////////////
  setStateNoRender(statekey, stateValue) {
    this.debugMsg('MainPage: setStateNoRender(' + statekey + ', ' + stateValue + ')');
    return {
      statekey: stateValue
    }
  }

  ////////////////////////////////////////////////////////////////
  // Set the inline style attribute.  styles must be within {}
  ////////////////////////////////////////////////////////////////
  setStyleAttribute(styleKey, styleText) {
    this.debugMsg('MainPage: setStyleAttribute(' + styleKey + ', ' + styleText + ')');
    this.state[styleKey] = styleText;
  }

  ////////////////////////////////////////////////////////////////
  // sleep process for a set amount of time
  ////////////////////////////////////////////////////////////////
  sleep(time=this.state.sleepDelay) {
    this.debugMsg('MainPage: Sleep Delay(' + time + ')');
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  ////////////////////////////////////////////////////////////////
  // show or hide the element block
  ////////////////////////////////////////////////////////////////
  showHideElement(elementId, showElement=false) {     
    let param = showElement ? 'True' : 'False';

    this.debugMsg('MainPage: showHideElement(' + elementId + ',' + param +')' );

    if (showElement) {
        this.state.toggleDisplayAttribute(elementId,"block");
    } else {
        this.state.toggleDisplayAttribute(elementId,"none");
    }
  }

  ////////////////////////////////////////////////////////////////
  // toggle display attribute on element id selector
  // set the userValue parameter to override the toggle and set to a specific value (block or none)
  ////////////////////////////////////////////////////////////////
  toggleDisplayAttribute(idSelector, userValue="") {
    var selector = document.getElementById(idSelector);
    if (userValue === "") {
      if (selector.style.display === "none" || selector.style.display === "") {
        selector.style.display = "block";
      } else {
        selector.style.display = "none";
      }
    } else {
      selector.style.display = userValue;
    }
    this.debugMsg('MainPage: toggleDisplayAttribute(' + idSelector + ', ' + selector.style.display + ')');
  }

  ////////////////////////////////////////////////////////////////
  // open close the help modal window
  ////////////////////////////////////////////////////////////////
  toggleHelp(stateKey, show) {
    this.debugMsg('MainPage: toggleHelp: toggleHelp()');
    this.state[stateKey] = show;
    this.debugMsg('HelpFile: toggleHelp(' + this.state.convertBoolean(show) + ')');
    this.state.refreshPage();
  }

  render() {
    this.debugMsg('MainPage.js : render()');
    return (
        <div className="MainPage">
            <Access global={this.state}/>
            <Upload global={this.state}/>
            <Email global={this.state}/>
            <Submit global={this.state}/>
            <IssueLinks global={this.state}/>
            <MessageBox global={this.state}/>
            <HelpFile global={this.state}/>
            <HelpAccess global={this.state}/>
        </div>
    );
  }
}

export default MainPage;
