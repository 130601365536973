
import axios from 'axios';

import { loginServer , devEnvAuth } from './../constants';

const isProdEnv = ( ) => { return /[Pp]rod/.test( process.env.REACT_APP_ENVIRONMENT ); }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * 
 * 
 * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const DEFINE_SESSION_TOKEN = "DEFINE_SESSION_TOKEN";
export const loadUserData = ( token ) => { 

	if( isProdEnv() ) {

		return function ( dispatch ) {

			dispatch( { type : DEFINE_SESSION_TOKEN , data : token } ); // stores token for later use
	    	dispatch( loadingUserData() );

	        axios.get( loginServer + '/' + token ) 
		        .then( response => {
		        	dispatch( loadedUserData( response.data ) );
		        } ).catch( (error) => {
		            console.log( error );
		        } );
	    }

	} else {

		return function ( dispatch ) {
	    	dispatch( loadingUserData() );
	    	dispatch( loadedUserData( devEnvAuth ) );
	    }

	}

}

export const LOADING_USER_DATA = "LOADING_USER_DATA";
export const loadingUserData = ( ) => { return { type : LOADING_USER_DATA }; }

export const LOADED_USER_DATA = "LOADED_USER_DATA";
export const loadedUserData = ( d ) => { return { type : LOADED_USER_DATA , data : d }; }

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * 
 * 
 * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */